import { Button } from '@components/ui'
import Heading from '@components/ui/Heading'
import { FC } from 'react'
import s from './HeadingBlock.module.css'
import cn from 'classnames'

interface props {
  primary: {
    background_colour: string
    body_text: {
      text: string
    }[]
    button_link: string
    button_style: string
    button_text: string
    heading: {
      text: string
      type: string
    }[]
    hide_button: boolean
    top_padding: string
    padding_bottom: string
    mobile_padding_top: string
    mobile_padding_bottom: string
    bottom_border: boolean
    mobile_alignment: string
  }
}

const HeadingBlock: FC<props> = ({ primary }) => {
  const {
    background_colour,
    body_text,
    button_link,
    button_style,
    button_text,
    heading,
    hide_button,
    top_padding,
    padding_bottom,
    mobile_padding_top,
    mobile_padding_bottom,
    bottom_border,
    mobile_alignment,
  } = primary
  return (
    <section className="headingBlockContainer" style={{ backgroundColor: background_colour }}>
      <div className="wrapper">
        <div
          className={cn(
            ' border-lightGrey laptop:max-w-desktop mx-auto grid grid-cols-8 laptop:grid-cols-12 gap-12 laptop:gap-16',
            { 'border-b': bottom_border }
          )}
        >
          <div
            className={cn(
              'heading-block__inner laptop:items-center col-span-8 laptop:col-start-3 flex flex-col w-full',
              {
                'items-start': mobile_alignment === 'Left',
                'items-center': mobile_alignment !== 'Left',
                'pb-40 laptop:pb-80': bottom_border,
              }
            )}
          >
            {heading[0]?.text && (
              <div className="mb-20 text-center">
                <Heading type={heading[0]?.type} text={heading[0]?.text} />
              </div>
            )}
            {body_text && (
              <div className="heading-block__body">
                {body_text.map((paragraph, i) => (
                  <p key={i} className={cn(s.body, 'laptop:text-center', { 'text-left': mobile_alignment === 'Left' })}>
                    {paragraph?.text}
                  </p>
                ))}
              </div>
            )}
            {!hide_button && button_text && (
              <Button
                Component="a"
                href={button_link}
                variant={`${button_style.toLowerCase()}`}
                className="mt-30 laptop:mt-40"
              >
                {button_text}
              </Button>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .headingBlockContainer {
          padding-top: ${top_padding};
          padding-bottom: ${padding_bottom};
        }
        @media (max-width: 768px) {
          .headingBlockContainer {
            padding-top: ${mobile_padding_top};
            padding-bottom: ${mobile_padding_bottom};
          }
        }
        .heading-block__body p:not(:last-of-type) {
          margin-bottom: 20px;
        }
      `}</style>
    </section>
  )
}

export default HeadingBlock
