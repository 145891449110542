import { FC } from 'react'

interface props {
  type: string
  text: string
}

const Heading: FC<props> = ({ type, text }) => {
  switch (type) {
    case 'heading1':
      return <h1 className="heading-1">{text}</h1>
    case 'heading2':
      return <h2 className="heading-2">{text}</h2>
    case 'heading3':
      return <h3 className="heading-3">{text}</h3>
    case 'heading4':
      return <h4 className="heading-4">{text}</h4>
    case 'heading5':
      return <h5 className=" laptop:text-24 laptop:leading-36 text-16 leading-24 font-g-regular font-normal tracking-none">{text}</h5>
    //This component should be used in Heading Block only.
    case 'heading6':
      return (
        <>
          <h6 className="heading-6 hidden laptop:block">{text}</h6>
          <h5 className="text-16 leading-24 font-g-regular font-normal tracking-none block laptop:hidden">{text}</h5>
        </>
      )
    default:
      return <h1 className="heading-1">{text}</h1>
  }
}

export default Heading
